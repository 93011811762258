<template>
	<section class="mb-5">
		<div class="row">
			<div class="col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-5">
						<label>{{ $t('employeeAdvisorNote.status') }}</label>
					</div>
					<div class="col">
						<select v-model="note" class="form-control" :disabled="disabled">
							<option value=""></option>
							<option value="Rådgivning">
								{{ $t('employeeAdvisorNote.dropdown.advising') }}
							</option>
							<option value="Underskrift">
								{{ $t('employeeAdvisorNote.dropdown.signature') }}
							</option>
							<option value="Antagelse">
								{{ $t('employeeAdvisorNote.dropdown.assumption') }}
							</option>
							<option value="Afkald fuld">
								{{ $t('employeeAdvisorNote.dropdown.waiverFull') }}
							</option>
							<option value="Afkald delvis">
								{{ $t('employeeAdvisorNote.dropdown.waiverPartial') }}
							</option>
							<option value="Ok">
								{{ $t('employeeAdvisorNote.dropdown.ok') }}
							</option>
						</select>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'UserAdvisorNote',
	components: {},
	props: {
		value: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
		},
	},
	computed: {
		note: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
	},
}
</script>

<style></style>
