<template>
	<section class="container">
		<b-form @submit.prevent="onSubmit">
			<div class="row mb-5 justify-content-center">
				<div class="col-lg-6 col-12">
					<center>
						<router-link :to="{ name: 'User', params: { id: employeeId } }">
							<BaseActionButton type="submit" id="signupButton"> Besøg konto </BaseActionButton></router-link
						>
					</center>
					<employeeInfo v-model="employeeInfo" :disabled="disabled"></employeeInfo>
				</div>
			</div>

			<div class="row mb-5 justify-content-center" v-show="$can('read', 'EmployeePolicy', 'comment')">
				<div class="col-lg-6 col-12">
					<center>
						<label>
							<b>Kommentar fra virksomhedsadmin ved indtrædelse</b>
						</label>
						<InputTextArea v-model="employeePolicy.companyAdminComment" :disabled="true"></InputTextArea>
					</center>
				</div>
			</div>

			<div class="row mb-5 justify-content-center" v-show="$can('read', 'EmployeePolicy', 'comment')">
				<div class="col-lg-6 col-12">
					<center>
						<p>
							<b>{{ $t('employeeDetail.conditions.advisorNote') }}</b>
							<advisor-note v-model="employeePolicy.advisorNote"  />
						</p>
						<label>
							<b>Kommentar fra rådgiver</b>
						</label>
						<InputTextArea v-model="employeePolicy.comment"></InputTextArea>
					</center>
				</div>
			</div>
			<div class="row mb-5" v-show="$can('read', 'EmployeePolicy', 'comment')">
				<div class="col-lg-6 col-12">
					<BaseActionButton class="btn-update" v-bind:clicked="clicked" v-bind:disabled="clicked" type="submit" id="signupButton">
						{{ $t('global.update') }}
					</BaseActionButton>
				</div>
			</div>
		</b-form>
	</section>
</template>

<script>
import EmployeeInfo from '../components/EmployeeInfo.vue'
import InputTextArea from '../../../global/atomComponents/InputTextArea.vue'
import AdvisorNote from '@/modules/general/employee/components/AdvisorNote'

export default {
	name: 'CompanyEmployeeInfo',
	components: {
		'employeeInfo': EmployeeInfo,
		InputTextArea,
		'advisor-note': AdvisorNote,
	},
	async mounted() {
		this.$parent.$emit('updateLoader')
	},
	data() {
		return {
			clicked: false,
			disabled: true,
		}
	},
	methods: {
		async onSubmit() {
			this.clicked = true
			var policyId = this.employeePolicy.id	
			await this.$store.dispatch('employeeVuex/updateEmployeePolicy', {
				id: policyId,
				data: this.employeePolicy,
				companyId: this.companyId,
				employeeId: this.employeeId,
			})
			this.toast('Success', 'Medarbejderens policy er nu opdateret', true)
			this.clicked = false
		},
	},
	computed: {
		companyId() {
			return this.$route.params.companyId
		},
		employeeId() {
			return this.$route.params.employeeId
		},
		employeeInfo() {
			const employee = this.$store.getters['employeeVuex/user']
			return employee
		},
		employeePolicy() {
			return this.$store.getters['employeeVuex/employeePolicy']
		},
	},
}
</script>

<style></style>
